<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">
        Upload Statuses
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Statuses</a></li>
          <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-3">
        <form action="#">
          <div class="form-group d-lg-flex">
            <input type="number" class="form-control" @change="paginateChange" placeholder="perPage" v-model="paginate">
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
<!--            <h4 class="card-title">Statuses</h4>-->
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <h4 class="card-title">{{ list_title }}</h4>
              <download-excel
                class="btn btn-success"
                :data="statuses"
                :fields="json_fields"
                worksheet="My Worksheet"
                name="upload_statuses.xlsx">
                Download Status
              </download-excel>
            </div>
            <b-table ref="table" :items="statuses" :busy="isBusy" id="table-list" responsive :per-page="perPage" :current-page="currentPage" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
              <template v-slot:cell(action)="data">
                <b-badge pill variant="outline-info">
                  <router-link :to="{ name: 'upload-process', params: {id: data.item.mapbox_upload_id} }">View</router-link>
                </b-badge>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              last-number
              aria-controls="table-list"
              align="right">
            </b-pagination>
          </div>
        </div>
      </div>
      </div>
    <div>
    </div>
  </section>
</template>
<script>
/* eslint-disable no-debugger, no-console */
import Vue from "vue"
import SortedTablePlugin from "vue-sorted-table"
import _ from "lodash";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  data: function() {
    return {
      searchInput: "",
      sortBy: 'name',
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      currentPage: 1,
      perPage: 0,
      paginate: 10,
      isBusy: true,
      fields: [
        { key: 'mapbox_upload_id', sortable: true },
        { key: 'tileset_url', sortable: true },
        { key: 'city', sortable: true},
        { key: 'id', sortable: true },
        { key: 'created_at', sortable: true },
        { key: 'updated_at', sortable: true },
        { key: 'action', sortable: false}
      ],
      json_fields: {
        'ID': 'id',
        "City": "city",
        'Created': 'created_at',
        'mapbox_upload_id': 'mapbox_upload_id',
        'Tileset URL': 'tileset_url',
      }
    }
  },
  computed: {
    rows() {
      return this.$store.state.plan.status_meta.total
    },
    paginationPageSize() {
      return this.paginate
    },
    statuses() {
      console.log(this.$store.state.plan.upload_statuses)
      return this.$store.state.plan.upload_statuses
    },
  },
  watch: {
    currentPage() {
      if(this.currentPage != this.$store.state.transaction.meta.current_page)
        this.fetchStatuses(this.currentPage)
    },
  },
  methods: {
    fetchStatuses(current_page = 1) {
      this.isBusy = true

      const payload = {
        meta: {
          page: current_page,
          paginate: this.paginate,
        },
      }

      for (const [key, value] of Object.entries(payload.meta)) {
        if(value == "")
          delete payload.meta[key]
      }

      this.$store.dispatch("plan/fetchUploadStatuses", payload)
      .then(() => {
        this.isBusy = false
      })
      .catch(() => {
        this.isBusy = false
      })
    },
    paginateChange: _.debounce(function() {
      if (this.paginate > 0 && this.paginate != "")
        this.fetchStatuses()
    }, 500),
  },
  created() {
    this.fetchStatuses()
 },
 components: {
 }
}
</script>
